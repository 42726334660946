
import {Component, Vue} from 'vue-property-decorator';
import router from "@/router";
import {SquareButton} from "carps-ui";

@Component({
  components: {
    SquareButton
  },
})
export default class NotFound extends Vue {

  public home(): void {
    router.push({path: '/'})
  }
}
